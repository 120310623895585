<script>
import { useRoute, useRouter } from 'vue-router';
import { h } from 'vue';
import store from "@/store";
export default {
  name: 'AuthRedirect',
  created() {
    // const hash = window.location.search.slice(1)
    // if (window.localStorage) {
    //   window.localStorage.setItem('x-admin-oauth-code', hash)
    //   window.close()
    // }
    const route = useRoute();
    const router = useRouter();
    const queryParams = route.query;
    const { url, uname, pwd } = queryParams;
    store
      .dispatch("user/login", {
        code: uname,
        password: pwd,
        logOnType: 0,
        logOnHuman: null,
      })
      .then(() => {

        router.push(url)
      }).catch(() => {
        console.log("err")
      });
  },
  render: function () {
    return h() // avoid warning message
  }
}
</script>
